import * as React from "react"
import { FC } from "react"
import SEO from "../components/Seo"
import Layout from "../components/Layout"

interface Props {
  data: any
}

const BuildYourOwnGiftBox: FC<Props> = ({ data }) => {
  return (
    <Layout>
      <SEO title="404: Not found" description={"not found"} />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
  // return (
  //   <BuildYourOwnPage
  //     title="Build Your Own Gift Box"
  //     description="Build your own gift box description"
  //     boxes={productTransformer.multipleFromGatsby(data.boxes.nodes)}
  //     giftCards={productTransformer.multipleFromGatsby(data.giftCards.nodes)}
  //     products={productTransformer.multipleFromGatsby(data.products.nodes)}
  //   >
  //     <p>
  //       Tailor-make the perfect present for whoever you want to send a gift to, no matter the
  //       occasion. Shopping for yourself? Cherry pick a selection of social enterprise products just
  //       to your taste and make your own hamper. These gifts have the potential to change the world
  //       because we and our brands are in the business of doing good.
  //     </p>
  //     <p>
  //       To build your own hamper, first choose the size of gift box you would like, then add your
  //       items – from food and drinks, homewares and more. If your items won’t fit in the box you’ve
  //       chosen, then we’ll let you know you need to swap an item or upsize your box. Whichever gifts
  //       you choose to send, they’ll be packaged in a recyclable box made from recycled materials and
  //       printed using water-based inks. Have questions about our Build Your Own gifts?{" "}
  //       <GLink to={"/contact"}>We’re here to help.</GLink>
  //     </p>
  //   </BuildYourOwnPage>
  // )
}

// export const query = graphql`
//   query {
//     boxes: allWpProduct(
//       filter: { productTags: { nodes: { elemMatch: { slug: { eq: "byo-box" } } } } }
//     ) {
//       nodes {
//         ...FullProduct
//       }
//     }
//     giftCards: allWpProduct(
//       filter: { productTags: { nodes: { elemMatch: { slug: { eq: "byo-gift-card" } } } } }
//     ) {
//       nodes {
//         ...FullProduct
//       }
//     }
//     products: allWpProduct(
//       filter: { productTags: { nodes: { elemMatch: { slug: { eq: "byo" } } } } }
//     ) {
//       nodes {
//         ...FullProduct
//       }
//     }
//   }
// `

export default BuildYourOwnGiftBox
